// src/theme.js (or any suitable filename)
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Changa, sans-serif", // Set your custom font-family here
    fontSize: 14,
  },

  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       ...(ownerState.variant === "outlined" &&
    //         ownerState.color === "primary" && {
    //           backgroundColor: "#fff",
    //         }),
    //     }),
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
  },
});

export default theme;
