import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_COURSES_SUBSCRIBING_REQUEST,
  GET_COURSES_SUBSCRIBING_SUCCESS,
  GET_COURSES_SUBSCRIBING_FAIL,
  GET_COURSE_SUBSCRIBING_REQUEST,
  GET_COURSE_SUBSCRIBING_SUCCESS,
  GET_COURSE_SUBSCRIBING_FAIL,
  CLEAR_COURSE_SUBSCRIBING,
  CLEAR_COURSES_SUBSCRIBING,
  SELECT_COURSE_SUBSCRIBING,
  CLEAR_SELECTED_COURSE_SUBSCRIBING,
  COURSE_SUBSCRIBE_REQUEST,
  COURSE_SUBSCRIBE_SUCCESS,
  COURSE_SUBSCRIBE_DONE,
  COURSE_SUBSCRIBE_FAIL,
  ACCEPT_SUBSCRIBE_REQUEST_REQUEST,
  ACCEPT_SUBSCRIBE_REQUEST_SUCCESS,
  ACCEPT_SUBSCRIBE_REQUEST_FAIL,
  GET_SUBSCRIBE_REQUEST_REQUEST,
  GET_SUBSCRIBE_REQUEST_SUCCESS,
  GET_SUBSCRIBE_REQUEST_FAIL,
} from "../constants/courseConstants";

export const coursesSubscribingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COURSES_SUBSCRIBING_REQUEST:
      return {
        ...state,
        loadingCoursesSubscribing: true,
        errMsgCoursesSubscribing: null,
        coursesSubscribing: [],
      };

    case GET_COURSES_SUBSCRIBING_SUCCESS:
      return {
        ...state,
        loadingCoursesSubscribing: false,
        coursesSubscribing: action.payload,
      };

    case GET_COURSES_SUBSCRIBING_FAIL:
      return {
        ...state,
        loadingCoursesSubscribing: false,
        errMsgCoursesSubscribing: action.payload,
        coursesSubscribing: [],
      };

    case SELECT_COURSE_SUBSCRIBING:
      const selectedCourse = state.coursesSubscribing.find(
        (course) => course.id === action.payload
      );
      return {
        ...state,
        selectedCourse: selectedCourse || null,
      };

    case CLEAR_SELECTED_COURSE_SUBSCRIBING:
      return {
        ...state,
        loadingCoursesSubscribing: false,
        errMsgCoursesSubscribing: null,
        selectedCourse: null,
      };

    case COURSE_SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscribingRequest: true,
        errMsgCoursesSubscribing: null,
      };

    case COURSE_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribingRequest: false,
        subscribingRequestSuccess: true,
      };

    case COURSE_SUBSCRIBE_DONE:
      return {
        ...state,
        subscribingRequestSuccess: false,
      };

    case COURSE_SUBSCRIBE_FAIL:
      return {
        ...state,
        subscribingRequest: false,
        errMsgCoursesSubscribing: action.payload,
      };

    default:
      return state;
  }
};

export const courseSubscribingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COURSE_SUBSCRIBING_REQUEST:
      return {
        ...state,
        loadingCourseSubscribing: true,
        errMsgCourseSubscribing: null,
        courseSubscribing: null,
      };

    case GET_COURSE_SUBSCRIBING_SUCCESS:
      return {
        ...state,
        loadingCourseSubscribing: false,
        courseSubscribing: action.payload,
      };

    case GET_COURSE_SUBSCRIBING_FAIL:
      return {
        ...state,
        loadingCourseSubscribing: false,
        errMsgCourseSubscribing: action.payload,
        courseSubscribing: null,
      };

    default:
      return state;
  }
};

export const coursesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COURSES_REQUEST:
      return {
        ...state,
        loadingCourses: true,
        errMsgCourses: null,
        courses: null,
      };

    case GET_COURSES_SUCCESS:
      return {
        ...state,
        loadingCourses: false,
        courses: action.payload,
      };

    case GET_COURSES_FAIL:
      return {
        ...state,
        loadingCourses: false,
        errMsgCourses: action.payload,
        courses: null,
      };

    default:
      return state;
  }
};

export const subscribeRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIBE_REQUEST_REQUEST:
      return {
        ...state,
        loadingRequests: true,
        errMsgRequests: null,
        requests: null,
      };

    case GET_SUBSCRIBE_REQUEST_SUCCESS:
      return {
        ...state,
        loadingRequests: false,
        requests: action.payload,
      };

    case GET_SUBSCRIBE_REQUEST_FAIL:
      return {
        ...state,
        loadingRequests: false,
        errMsgRequests: action.payload,
        requests: null,
      };

    default:
      return state;
  }
};
