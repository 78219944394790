import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { login } from "../redux/actions/userActions";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
    navigate("/dashboard");
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-container">
        <TextField
          required
          autoComplete="off"
          className="login-input"
          type="text"
          label="اسم المستخدم"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          required
          className="login-input"
          type="password"
          label="كلمة المرور"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button variant="contained" type="submit" className="login-button">
          تسجيل الدخول
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
