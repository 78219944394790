import React from "react";

const NavBar = () => {
  return (
    <nav>
      <img
        src={`${process.env.PUBLIC_URL}/images/logo.png`}
        width={"150px"}
        height={"40px"}
      />
    </nav>
  );
};

export default NavBar;
