import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { getCourseSubscribeBasicData } from "../redux/actions/mainActions";
import SubscribeSuccess from "../components/page-component/SubscribeSuccess";
import { subscribeCourse } from "../redux/actions/courseActions";

const CourseRegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { loadingNationalities, errMsgNationalities, nationalities } =
    useSelector((state) => state.nationalityList);

  const { loadingQualifications, errMsgQualifications, qualifications } =
    useSelector((state) => state.qualificationList);

  const { loadingTrainingTimes, errMsgTrainingTimes, trainingTimes } =
    useSelector((state) => state.trainingTimeList);

  const { selectedCourse, subscribingRequest, subscribingRequestSuccess } =
    useSelector((state) => state.coursesSubscribing);

  const [email, setEmail] = useState("");

  const [arabicName, setArabicName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [address, setAddress] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [gender, setGender] = useState("male");
  const [nationality, setNationality] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [trainingTime, setTrainingTime] = useState(null);

  const handlePhone1Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 9); // Limit to maximum length (adjust as needed)
    setPhone1(maxLengthValue);
  };

  const handlePhone2Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 9); // Limit to maximum length (adjust as needed)
    setPhone2(maxLengthValue);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (id) {
      const requestData = {
        course_instance: id,
        email: email,
        name_arabic: arabicName,
        name_english: englishName,
        address: address,
        phone_1: phone1,
        phone_2: phone2,
        gender: gender,
        nationality: nationality.id,
        qualification: qualification.id,
        training_time: trainingTime.id,
      };
      dispatch(subscribeCourse(requestData));
    }
  };

  useEffect(() => {
    dispatch(getCourseSubscribeBasicData());
  }, []);

  return (
    <form onSubmit={handleSubscribe} className="course-register-page">
      {subscribingRequestSuccess === true ? (
        <SubscribeSuccess />
      ) : (
        <>
          {loadingNationalities === true ||
          loadingQualifications === true ||
          loadingTrainingTimes === true ? (
            <CircularProgress />
          ) : (
            <>
              <Row>
                <Col sm={12} md={6} style={{ marginBottom: "15px" }}>
                  <div className="course-register-input-container">
                    <TextField
                      required
                      autoComplete="off"
                      className="course-register-input"
                      label="البريد الإلكتروني"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      className="course-register-input"
                      label="الإسم بالعربية"
                      type="text"
                      value={arabicName}
                      onChange={(e) => setArabicName(e.target.value)}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      className="course-register-input"
                      label="الإسم بالإنجليزية"
                      type="text"
                      value={englishName}
                      onChange={(e) => setEnglishName(e.target.value)}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      className="course-register-input"
                      label="العنوان"
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <TextField
                      required
                      autoComplete="off"
                      className="course-register-input"
                      label="رقم الهاتف 1"
                      placeholder="92xxxxxxx"
                      type="text"
                      s
                      value={phone1}
                      onChange={handlePhone1Change}
                      InputProps={{
                        pattern: "[0-9+]*", // Accept only digits and plus (+) sign
                        maxLength: 9, // Maximum length of phone number (adjust as needed)
                        endAdornment: (
                          <InputAdornment position="end">218+</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      autoComplete="off"
                      className="course-register-input"
                      label="رقم الهاتف 2"
                      type="text"
                      placeholder="92xxxxxxx"
                      value={phone2}
                      onChange={handlePhone2Change}
                      InputProps={{
                        pattern: "[0-9+]*", // Accept only digits and plus (+) sign
                        maxLength: 9, // Maximum length of phone number (adjust as needed)
                        endAdornment: (
                          <InputAdornment position="end">218+</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <FormControl
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <FormLabel>الجنس</FormLabel>
                    <RadioGroup
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      name="gender-radio-group"
                      row
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="ذكر"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="أنثى"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="course-register-input-container">
                    <Autocomplete
                      disablePortal
                      options={nationalities}
                      getOptionLabel={(option) => option.name}
                      value={nationality}
                      onChange={(e, newValue) => setNationality(newValue)}
                      className="course-register-input"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          {...params}
                          label="الجنسية"
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      options={qualifications}
                      getOptionLabel={(option) => option.name}
                      value={qualification}
                      onChange={(e, newValue) => setQualification(newValue)}
                      className="course-register-input"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          {...params}
                          label="المؤهل العلمي"
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      options={trainingTimes}
                      getOptionLabel={(option) => option.name}
                      value={trainingTime}
                      onChange={(e, newValue) => setTrainingTime(newValue)}
                      className="course-register-input"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          {...params}
                          label="الفترة التدريبية"
                        />
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <div className="course-register-button-container">
                <Button
                  type="submit"
                  variant="contained"
                  className="register-btn"
                  endIcon={
                    subscribingRequest === true && (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    )
                  }
                >
                  إرسال طلب إشتراك
                </Button>
                <Button
                  variant="contained"
                  className="cancel-register-btn"
                  onClick={() => navigate(-1)}
                >
                  الرجوع
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default CourseRegisterPage;
