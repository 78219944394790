import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  CLEAR_USERS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CLEAR_USER,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUIRED,
  CHANGE_PASSWORD_REQUIRED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loadingLogin: true,
        errMsgLogin: null,
        userInfo: null,
        passwordChangeRequired: false,
        passwordChangeSuccess: false,
      };

    case USER_LOGIN_SUCCESS:
      return {
        loadingLogin: false,
        errMsgLogin: null,
        userInfo: action.payload,
        passwordChangeRequired: false,
        passwordChangeSuccess: false,
      };

    case USER_LOGIN_FAIL:
      return {
        loadingLogin: false,
        errMsgLogin: action.payload,
        userInfo: null,
        passwordChangeRequired: false,
        passwordChangeSuccess: false,
      };

    case CHANGE_PASSWORD_REQUIRED:
      return {
        loadingLogin: false,
        errMsgLogin: null,
        userInfo: null,
        passwordChangeRequired: true,
        passwordChangeSuccess: false,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        loadingLogin: false,
        errMsgLogin: null,
        userInfo: null,
        passwordChangeRequired: false,
        passwordChangeSuccess: true,
      };

    case USER_LOGOUT:
      return {
        loadingLogin: false,
        errMsgLogin: null,
        userInfo: null,
        passwordChangeRequired: false,
        passwordChangeSuccess: false,
      };

    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loadingUserRegister: true,
        errMsgUserRegister: null,
        userInfo: null,
      };

    case USER_REGISTER_SUCCESS:
      return {
        loadingUserRegister: false,
        errMsgUserRegister: null,
        userInfo: action.payload,
      };

    case USER_REGISTER_FAIL:
      return {
        loadingUserRegister: false,
        errMsgUserRegister: action.payload,
        userInfo: null,
      };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { loadingUser: true, errMsgUser: null, user: null };

    case GET_USER_SUCCESS:
      return { loadingUser: false, errMsgUser: null, user: action.payload };

    case GET_USER_FAIL:
      return { loadingUser: false, errMsgUser: action.payload, user: null };

    case CLEAR_USER:
      return { loadingUser: false, errMsgUser: null, user: null };

    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        loadingResetPassowrd: true,
        errMsgResetPassowrd: null,
        msg: null,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        loadingResetPassowrd: false,
        errMsgResetPassowrd: null,
        msg: action.payload,
      };

    case RESET_PASSWORD_FAIL:
      return {
        loadingResetPassowrd: false,
        errMsgResetPassowrd: action.payload,
        msg: null,
      };

    default:
      return state;
  }
};

export const usersListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { loadingUsers: true, errMsgUsers: null, users: [] };

    case GET_USERS_SUCCESS:
      return { loadingUsers: false, errMsgUsers: null, users: action.payload };

    case GET_USERS_FAIL:
      return { loadingUsers: false, errMsgUsers: action.payload, users: [] };

    case CLEAR_USERS:
      return { loadingUsers: false, errMsgUsers: null, users: [] };

    default:
      return state;
  }
};
