import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  GET_COURSES_SUBSCRIBING_REQUEST,
  GET_COURSES_SUBSCRIBING_SUCCESS,
  GET_COURSES_SUBSCRIBING_FAIL,
  GET_COURSE_SUBSCRIBING_REQUEST,
  GET_COURSE_SUBSCRIBING_SUCCESS,
  GET_COURSE_SUBSCRIBING_FAIL,
  CLEAR_COURSE_SUBSCRIBING,
  CLEAR_COURSES_SUBSCRIBING,
  SELECT_COURSE_SUBSCRIBING,
  CLEAR_SELECTED_COURSE_SUBSCRIBING,
  COURSE_SUBSCRIBE_REQUEST,
  COURSE_SUBSCRIBE_SUCCESS,
  COURSE_SUBSCRIBE_DONE,
  COURSE_SUBSCRIBE_FAIL,
  ADD_COURSES_INSTANCE_REQUEST,
  ADD_COURSES_INSTANCE_SUCCESS,
  ADD_COURSES_INSTANCE_FAIL,
  UPDATE_COURSES_INSTANCE_REQUEST,
  UPDATE_COURSES_INSTANCE_SUCCESS,
  UPDATE_COURSES_INSTANCE_FAIL,
  GET_SUBSCRIBE_REQUEST_REQUEST,
  GET_SUBSCRIBE_REQUEST_SUCCESS,
  GET_SUBSCRIBE_REQUEST_FAIL,
  ACCEPT_SUBSCRIBE_REQUEST_REQUEST,
  ACCEPT_SUBSCRIBE_REQUEST_SUCCESS,
  ACCEPT_SUBSCRIBE_REQUEST_FAIL,
  REJECT_SUBSCRIBE_REQUEST_REQUEST,
  REJECT_SUBSCRIBE_REQUEST_SUCCESS,
  REJECT_SUBSCRIBE_REQUEST_FAIL,
} from "../constants/courseConstants";
import axiosNT from "../axiosInstances/axiosNoToken";
import axiosWT from "../axiosInstances/axiosWithToken";
import { addAlertMessage } from "./systemActions";
import { useNavigate } from "react-router-dom";
import { GET_TRAINING_TIMES_SUCCESS } from "../constants/trainingTimeConstants";

export const getCoursesSubscribing = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSES_SUBSCRIBING_REQUEST,
    });

    const { data } = await axiosNT.get("/training/courses-subscribing/get");

    dispatch({
      type: GET_COURSES_SUBSCRIBING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    // dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: GET_COURSES_SUBSCRIBING_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getCourseSubscribing = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSE_SUBSCRIBING_REQUEST,
    });

    const { data } = await axiosNT.get(
      `/training/course-subscribing/get/${id}`
    );

    dispatch({
      type: GET_COURSES_SUCCESS,
      payload: data.courses,
    });

    dispatch({
      type: GET_TRAINING_TIMES_SUCCESS,
      payload: data.training_time_list,
    });

    dispatch({
      type: GET_COURSE_SUBSCRIBING_SUCCESS,
      payload: data.course_subscribing,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: GET_COURSE_SUBSCRIBING_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COURSES_REQUEST,
    });

    const { data } = await axiosNT.get(`/training/courses/get`);

    dispatch({
      type: GET_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: GET_COURSES_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const subscribeCourse = (requestData) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_SUBSCRIBE_REQUEST,
    });

    const { data } = await axiosNT.post(
      "/training/courses-subscribing/subscribe",
      requestData
    );

    dispatch({
      type: COURSE_SUBSCRIBE_SUCCESS,
      payload: data,
    });

    setTimeout(() => {
      dispatch({
        type: COURSE_SUBSCRIBE_DONE,
      });
    }, 3000);
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: COURSE_SUBSCRIBE_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const updateCourseInstace = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_COURSES_INSTANCE_REQUEST,
    });

    const { data } = await axiosWT.put(
      "/training/course-subscribing/add-or-update",
      formData
    );

    dispatch({
      type: UPDATE_COURSES_INSTANCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: UPDATE_COURSES_INSTANCE_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const addCourseInstace = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COURSES_INSTANCE_REQUEST,
    });

    const { data } = await axiosWT.post(
      "/training/course-subscribing/add-or-update",
      formData
    );

    dispatch({
      type: ADD_COURSES_INSTANCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: ADD_COURSES_INSTANCE_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getSubscribeRequests = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBSCRIBE_REQUEST_REQUEST,
    });

    const { data } = await axiosWT.get("/training/subscribe-requests/get");

    dispatch({
      type: GET_SUBSCRIBE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: GET_SUBSCRIBE_REQUEST_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const acceptSubscribeRequests = (requestId) => async (dispatch) => {
  try {
    dispatch({
      type: ACCEPT_SUBSCRIBE_REQUEST_REQUEST,
    });

    const { data } = await axiosWT.put(
      "/training/subscribe-requests/accept-or-reject",
      { request_id: requestId, action: 1 }
    );

    dispatch({
      type: ACCEPT_SUBSCRIBE_REQUEST_SUCCESS,
    });

    dispatch({
      type: GET_SUBSCRIBE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: ACCEPT_SUBSCRIBE_REQUEST_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const rejectSubscribeRequests = (requestId) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_SUBSCRIBE_REQUEST_REQUEST,
    });

    const { data } = await axiosWT.put(
      "/training/subscribe-requests/accept-or-reject",
      { request_id: requestId, action: 0 }
    );

    dispatch({
      type: REJECT_SUBSCRIBE_REQUEST_SUCCESS,
    });

    dispatch({
      type: GET_SUBSCRIBE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: REJECT_SUBSCRIBE_REQUEST_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const selectCourseSubscribing = (id) => (dispatch) => {
  dispatch({ type: SELECT_COURSE_SUBSCRIBING, payload: id });
};

export const clearCoursesSubscribing = () => (dispatch) => {
  dispatch({ type: CLEAR_COURSES_SUBSCRIBING });
};

export const clearSelectedCourseSubscribing = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_COURSE_SUBSCRIBING });
};
