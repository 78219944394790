import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
  Button,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomDatePicker from "../components/CustomDatePicker";
import CourseStudentTable from "../components/table/CourseStudentTable";
import {
  addCourseInstace,
  getCourseSubscribing,
  updateCourseInstace,
} from "../redux/actions/courseActions";
import { getCourseSubscribeBasicData } from "../redux/actions/mainActions";

const CourseDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courses } = useSelector((state) => state.courses);
  const {
    loadingCourseSubscribing,
    errMsgCourseSubscribing,
    courseSubscribing,
  } = useSelector((state) => state.courseSubscribing);
  const { loadingTrainingTimes, errMsgTrainingTimes, trainingTimes } =
    useSelector((state) => state.trainingTimeList);

  const [course, setCourse] = useState(null);
  const [seats, setSeats] = useState("");
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());
  const [timelist, setTimeList] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      course: course,
      max_seats: seats,
      start_date: startDate,
      end_date: endDate,
      time: timelist,
      ...(id ? { course_instance: id } : {}),
    };

    dispatch(id ? updateCourseInstace(formData) : addCourseInstace(formData));
    navigate(-1);
  };

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Add the ID to the list if it doesn't exist
      if (!timelist.includes(id)) {
        setTimeList([...timelist, id]);
      }
    } else {
      // Remove the ID from the list if it exists
      const updatedIds = timelist.filter((selectedId) => selectedId !== id);
      setTimeList(updatedIds);
    }
  };

  useEffect(() => {
    if (courseSubscribing) {
      setCourse(courseSubscribing.course);
      setSeats(courseSubscribing.max_seats);
      setStartDate(courseSubscribing.start_date);
      setEndDate(courseSubscribing.end_date);
      setTimeList(courseSubscribing.time);
    }
  }, [courseSubscribing]);

  useEffect(() => {
    id
      ? dispatch(getCourseSubscribing(id))
      : dispatch(getCourseSubscribeBasicData());
  }, []);

  return (
    <form className="course-details-page" onSubmit={handleSubmit}>
      <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
        <Col sm={12} md={4} style={{ textAlign: "center" }}>
          <img
            src={courseSubscribing?.image}
            width={"200px"}
            height={"200px"}
            style={{ margin: "10px auto" }}
          />
        </Col>
        <Col style={{ width: "100%" }}>
          <div className="course-details-input-container">
            <Autocomplete
              disablePortal
              options={courses}
              getOptionLabel={(option) => option.name}
              value={courses.find((option) => option.id === course) || null}
              onChange={(e, newValue) =>
                setCourse(newValue ? newValue.id : null)
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField fullWidth required {...params} label="الكورس" />
              )}
            />
            <TextField
              required
              type="number"
              autoComplete="off"
              label="عدد المقاعد"
              style={{ width: "100%" }}
              value={seats}
              onChange={(e) => setSeats(e.target.value)}
            />
            <CustomDatePicker
              containerStyle={{ padding: "0", width: "100%" }}
              style={{ padding: "0", width: "100%" }}
              value={startDate}
            />
            <CustomDatePicker
              containerStyle={{ padding: "0", width: "100%" }}
              style={{ padding: "0", width: "100%" }}
              value={endDate}
            />
          </div>
        </Col>
        <Col sm={12} md={4}>
          <div className="time-container">
            <div className="time-container">
              <FormGroup row>
                {trainingTimes &&
                  trainingTimes.map((time) => (
                    <FormControlLabel
                      key={time.id}
                      control={
                        <Checkbox
                          checked={timelist.includes(time.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, time.id)
                          }
                        />
                      }
                      value={time.id}
                      label={time.name}
                    />
                  ))}
              </FormGroup>
            </div>
            {id ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className="course-details-button"
                style={{ backgroundColor: "var(--color-2)" }}
              >
                حفظ
              </Button>
            ) : (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className="course-details-button"
                style={{ backgroundColor: "var(--color-2)" }}
              >
                إنشاء
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <CourseStudentTable students={courseSubscribing?.students} />
    </form>
  );
};

export default CourseDetailsPage;
