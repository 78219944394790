import {
  ADD_ALERT_MESSAGE,
  REMOVE_ALERT_MESSAGE,
  CLEAR_ALERT_MESSAGE,
} from "../constants/systemConstants";

export const addAlertMessage =
  (text, type = "info") =>
  (dispatch) => {
    dispatch({
      type: ADD_ALERT_MESSAGE,
      payload: { type: type, text: text },
    });
  };

export const removeAlertMessage = (messageId) => (dispatch) => {
  dispatch({ type: REMOVE_ALERT_MESSAGE, payload: messageId });
};
