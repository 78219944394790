import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import routes from "./routes";

function App() {
  const { loading, errorMsg, userInfo } = useSelector(
    (state) => state.userLogin
  );
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          if ((route.isPrivate === true) & (userInfo === null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/login"} />}
              />
            );
          else if ((route.mustLogout === true) & (userInfo !== null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/dashboard"} />}
              />
            );
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
