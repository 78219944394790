import MainContainer from "./containers/MainContainer";
import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage";
import CourseRegisterPage from "./pages/CourseRegisterPage";
import CoursesManagementPage from "./pages/CoursesManagementPage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import DashboardPage from "./pages/DashboardPage";
import SubscribeRequestPage from "./pages/SubscribeRequestPage";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
    isPrivate: false,
    mustLogout: true,
  },
  {
    path: "/",
    element: (
      <MainContainer>
        <MainPage />
      </MainContainer>
    ),
    isPrivate: false,
    mustLogout: false,
  },
  {
    path: "/course-subscribe/:id?",
    element: (
      <MainContainer>
        <CourseRegisterPage />
      </MainContainer>
    ),
    isPrivate: false,
    mustLogout: false,
  },
  {
    path: "/courses-management",
    element: (
      <MainContainer>
        <CoursesManagementPage />
      </MainContainer>
    ),
    isPrivate: true,
    mustLogout: false,
  },
  {
    path: "/courses-management/:id",
    element: (
      <MainContainer>
        <CourseDetailsPage />
      </MainContainer>
    ),
    isPrivate: true,
    mustLogout: false,
  },
  {
    path: "/dashboard",
    element: (
      <MainContainer>
        <DashboardPage />
      </MainContainer>
    ),
    isPrivate: true,
    mustLogout: false,
  },
  {
    path: "/subscribe-requests",
    element: (
      <MainContainer>
        <SubscribeRequestPage />
      </MainContainer>
    ),
    isPrivate: true,
    mustLogout: false,
  },
];

export default routes;
