import {
  GET_TRAINING_TIMES_REQUEST,
  GET_TRAINING_TIMES_SUCCESS,
  GET_TRAINING_TIMES_FAIL,
  CLEAR_TRAINING_TIMES,
} from "../constants/trainingTimeConstants";

export const trainingTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TRAINING_TIMES_REQUEST:
      return {
        loadingTrainingTimes: true,
        errMsgTrainingTimes: null,
        trainingTimes: [],
      };

    case GET_TRAINING_TIMES_SUCCESS:
      return {
        loadingTrainingTimes: false,
        errMsgTrainingTimes: null,
        trainingTimes: action.payload,
      };

    case GET_TRAINING_TIMES_FAIL:
      return {
        loadingTrainingTimes: false,
        errMsgTrainingTimes: action.payload,
        trainingTimes: [],
      };

    case CLEAR_TRAINING_TIMES:
      return {
        loadingTrainingTimes: false,
        errMsgTrainingTimes: null,
        trainingTimes: [],
      };

    default:
      return state;
  }
};
