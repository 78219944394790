import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { userLoginReducer } from "./reducers/userReducers";

import Cookies from "js-cookie";
import { nationalityReducer } from "./reducers/nationalityReducers";
import { qualificationReducer } from "./reducers/qualificationReducers";
import { trainingTimeReducer } from "./reducers/trainingTimeReducers";
import {
  courseSubscribingReducer,
  coursesReducer,
  coursesSubscribingReducer,
  subscribeRequestsReducer,
} from "./reducers/courseReducers";
import { alertMessagesReducer } from "./reducers/systemReducer";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  nationalityList: nationalityReducer,
  qualificationList: qualificationReducer,
  trainingTimeList: trainingTimeReducer,
  coursesSubscribing: coursesSubscribingReducer,
  courseSubscribing: courseSubscribingReducer,
  courses: coursesReducer,
  subscribeRequests: subscribeRequestsReducer,
  alertMessages: alertMessagesReducer,
});

const middleware = [thunk];

const userInfoFromCookie = Cookies.get("userInfo")
  ? JSON.parse(Cookies.get("userInfo"))
  : null;

const initialState = {
  userLogin: {
    loading: false,
    errorMsg: null,
    userInfo: userInfoFromCookie,
    passwordChangeRequired: false,
    passwordChangeSuccess: false,
  },
  nationalityList: {
    loadingNationalities: false,
    errMsgNationalities: null,
    nationalities: [],
  },
  qualificationList: {
    loadingQualifications: false,
    errMsgQualifications: null,
    qualifications: [],
  },
  trainingTimeList: {
    loadingTrainingTimes: false,
    errMsgTrainingTimes: null,
    trainingTimes: [],
  },
  coursesSubscribing: {
    loadingCoursesSubscribing: false,
    errMsgCoursesSubscribing: null,
    coursesSubscribing: [],
    selectedCourse: null,
    subscribingRequest: false,
    subscribingRequestSuccess: false,
  },
  courseSubscribing: {
    loadingCourseSubscribing: false,
    errMsgCourseSubscribing: null,
    courseSubscribing: null,
  },
  courses: {
    loadingCourses: false,
    errMsgCourses: null,
    courses: [],
  },
  subscribeRequests: {
    loadingRequests: false,
    errMsgRequests: null,
    requests: [],
  },
  alertMessages: { messages: [] },
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [...middleware],
  preloadedState: initialState,
});

export default store;
