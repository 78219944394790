import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoursesListTable from "../components/table/CoursesListTable";
import { getCoursesSubscribing } from "../redux/actions/courseActions";

const CoursesManagementPage = () => {
  const dispatch = useDispatch();

  const {
    loadingCoursesSubscribing,
    errMsgCoursesSubscribing,
    coursesSubscribing,
  } = useSelector((state) => state.coursesSubscribing);

  useEffect(() => {
    dispatch(getCoursesSubscribing());
  }, []);

  return (
    <div className="course-management-page">
      <CoursesListTable courses={coursesSubscribing} />
    </div>
  );
};

export default CoursesManagementPage;
