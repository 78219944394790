import axios from "axios";

const axiosNT = axios.create({
  baseURL: "https://api.futuretraining.ly",
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
  },
});

export default axiosNT;
