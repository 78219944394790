import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Row style={{ margin: "20px auto" }}>
        <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
          <div
            className="dashboard-button"
            onClick={() => navigate("/courses-management")}
          >
            <p>إدارة الكورسات</p>
          </div>
        </Col>
        <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
          <div
            className="dashboard-button"
            onClick={() => navigate("/courses-subscribe")}
          >
            <p>الكورسات المفتوحة</p>
          </div>
        </Col>
        <Col sm={12} md={4} style={{ marginBottom: "20px" }}>
          <div
            className="dashboard-button"
            onClick={() => navigate("/subscribe-requests")}
          >
            <p>طلبات الإشتراك</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardPage;
