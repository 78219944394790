import {
  GET_NATIONALITIES_REQUEST,
  GET_NATIONALITIES_SUCCESS,
  GET_NATIONALITIES_FAIL,
  CLEAR_NATIONALITIES,
} from "../constants/nationalityConstants";
import {
  GET_QUALIFICATIONS_REQUEST,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_FAIL,
  CLEAR_QUALIFICATIONS,
} from "../constants/qualificationConstants";
import {
  GET_TRAINING_TIMES_REQUEST,
  GET_TRAINING_TIMES_SUCCESS,
  GET_TRAINING_TIMES_FAIL,
  CLEAR_TRAINING_TIMES,
} from "../constants/trainingTimeConstants";

import axiosNT from "../axiosInstances/axiosNoToken";

export const getCourseSubscribeBasicData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NATIONALITIES_REQUEST,
    });
    dispatch({
      type: GET_QUALIFICATIONS_REQUEST,
    });
    dispatch({
      type: GET_TRAINING_TIMES_REQUEST,
    });

    const { data } = await axiosNT.get(
      "/training/course-subscribe-basic-data/get"
    );

    dispatch({
      type: GET_NATIONALITIES_SUCCESS,
      payload: data.nationalities,
    });
    dispatch({
      type: GET_QUALIFICATIONS_SUCCESS,
      payload: data.qualifications,
    });
    dispatch({
      type: GET_TRAINING_TIMES_SUCCESS,
      payload: data.training_time,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;

    // dispatch(addAlertMessage(message, "error"));

    dispatch({
      type: GET_NATIONALITIES_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
    dispatch({
      type: GET_QUALIFICATIONS_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
    dispatch({
      type: GET_TRAINING_TIMES_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
