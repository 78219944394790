import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SubscribeSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 2000);
  }, []);

  return (
    <div className="subscribe-success">
      <CheckCircleIcon
        style={{ color: "green", width: "200px", height: "200px" }}
      />
      <h3>تم إرسال طلب إشتراك بالكورس</h3>
      <p>شكراً لإشتراكك معنا سنقوم بمراجعة طلبك و الرد عليك في أقرب وقت</p>
    </div>
  );
};

export default SubscribeSuccess;
