import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const columns = [
  { id: "index", label: "ت" },
  { id: "name", label: "اسم الكورس", minWidth: 170 },
  {
    id: "startDate",
    label: "تاريخ البدء",
    minWidth: 50,
    format: (value) => value.toFixed(2),
  },
  {
    id: "endDate",
    label: "تاريخ الإنتهاء",
    minWidth: 50,
    format: (value) => value.toFixed(2),
  },
  {
    id: "maxSeats",
    label: "عدد المقاعد",
    minWidth: 50,
    align: "center",
  },
  {
    id: "availableSeats",
    label: "المقاعد المتوفرة",
    minWidth: 20,
    width: 20,
    align: "center",
  },
  {
    id: "view",
    label: "",
    minWidth: 20,
    width: 20,
    align: "center",
  },
];

function createData(
  id,
  index,
  name,
  startDate,
  endDate,
  maxSeats,
  availableSeats
) {
  return { id, index, name, startDate, endDate, maxSeats, availableSeats };
}

const CoursesListTable = ({ courses }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    courses &&
      setRows(
        courses.map((course, index) => {
          return createData(
            course.id,
            ++index,
            course.name,
            course.start_date,
            course.end_date,
            course.max_seats,
            course.available_seats
          );
        })
      );
  }, [courses]);

  return (
    <Paper
      sx={{
        width: "100%",
        flexGrow: "1",
        overflow: "hidden",
      }}
    >
      <TableContainer style={{ height: "600px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className="table-head-row"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return column.id === "view" ? (
                      <TableCell key={column.id} align={column.align}>
                        <IconButton
                          sx={{ color: "var(--color-1)" }}
                          onClick={() =>
                            navigate(`/courses-management/${row.id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CoursesListTable;
