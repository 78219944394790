import {
  GET_NATIONALITIES_REQUEST,
  GET_NATIONALITIES_SUCCESS,
  GET_NATIONALITIES_FAIL,
  CLEAR_NATIONALITIES,
} from "../constants/nationalityConstants";

export const nationalityReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NATIONALITIES_REQUEST:
      return {
        loadingNationalities: true,
        errMsgNationalities: null,
        nationalities: [],
      };

    case GET_NATIONALITIES_SUCCESS:
      return {
        loadingNationalities: false,
        errMsgNationalities: null,
        nationalities: action.payload,
      };

    case GET_NATIONALITIES_FAIL:
      return {
        loadingNationalities: false,
        errMsgNationalities: action.payload,
        nationalities: [],
      };

    case CLEAR_NATIONALITIES:
      return {
        loadingNationalities: false,
        errMsgNationalities: null,
        nationalities: [],
      };

    default:
      return state;
  }
};
