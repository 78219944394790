import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  CHANGE_PASSWORD_REQUIRED,
} from "../constants/userConstants";
import { addAlertMessage } from "./systemActions";
import axiosNT from "../axiosInstances/axiosNoToken";
import axiosWT from "../axiosInstances/axiosWithToken";
import Cookies from "js-cookie";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axiosNT.post("/users/login", {
      username: username,
      password: password,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    Cookies.set("userInfo", JSON.stringify(data), {
      // httpOnly: true, this wont let you access the token or any other else (hackers)
      secure: true, // Only send over HTTPS connections
      expires: 1, // Expire after 1 days
      sameSite: "strict" /* Restrict cross-site requests */,
    });
  } catch (error) {
    if (
      error.response.data.code &&
      error.response.data.code[0] === "password_reset_required"
    ) {
      dispatch({
        type: CHANGE_PASSWORD_REQUIRED,
      });
    } else {
      const message = error.response.data.message
        ? error.response.data.message
        : error.message;

      dispatch(addAlertMessage(message, "error"));
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  }
};
export const logout = () => async (dispatch) => {
  Cookies.remove("userInfo");
  dispatch({
    type: USER_LOGOUT,
  });
};
