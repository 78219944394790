import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const columns = [
  { id: "index", label: "ت" },
  { id: "arabicName", label: "اسم الطالب", minWidth: 170 },
  { id: "englishName", label: "اسم الطالب", minWidth: 170 },
  { id: "phone1", label: "هاتف 1", minWidth: 170 },
  { id: "phone2", label: "هاتف 2", minWidth: 170 },
  { id: "address", label: "العنوان", minWidth: 170 },
  { id: "nationality", label: "الجنسية", minWidth: 170 },
  { id: "qualification", label: "المؤهل العلمي", minWidth: 170 },
  { id: "training_time", label: "وقت التدريب", minWidth: 170 },
];

function createData(
  id,
  index,
  arabicName,
  englishName,
  phone1,
  phone2,
  address,
  nationality,
  qualification,
  training_time
) {
  return {
    id,
    index,
    arabicName,
    englishName,
    phone1,
    phone2,
    address,
    nationality,
    qualification,
    training_time,
  };
}

const CourseStudentTable = ({ students }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    students &&
      setRows(
        students.map((student, index) => {
          return createData(
            student.id,
            ++index,
            student.name_arabic,
            student.name_english,
            student.phone_1,
            student.phone_2,
            student.address,
            student.nationality_name,
            student.qualification_name,
            student.training_time_name
          );
        })
      );
  }, [students]);

  return (
    <Paper
      sx={{
        width: "100%",
        flexGrow: "1",
        overflow: "hidden",
      }}
    >
      <TableContainer style={{ height: "600px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className="table-head-row"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CourseStudentTable;
