import React, { Children } from "react";

import { Container } from "react-bootstrap";
import NavBar from "../components/NavBar";
// import Footer from "../components/Footer";
import AlertMessage from "../components/AlertMessage";

const MainContainer = ({ children }) => {
  return (
    <>
      <Container fluid className="main-container">
        <NavBar />
        <AlertMessage />
        {children}
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default MainContainer;
