import {
  ADD_ALERT_MESSAGE,
  REMOVE_ALERT_MESSAGE,
  CLEAR_ALERT_MESSAGE,
} from "../constants/systemConstants";

export const alertMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ALERT_MESSAGE:
      const maxId = state.messages.reduce(
        (maxId, msg) => Math.max(maxId, msg.id),
        0
      );
      return {
        ...state,
        messages: [...state.messages, { id: maxId + 1, ...action.payload }],
      };

    case REMOVE_ALERT_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((msg) => msg.id !== action.payload),
      };

    case CLEAR_ALERT_MESSAGE:
      return {
        messages: [],
      };

    default:
      return state;
  }
};
