import {
  GET_QUALIFICATIONS_REQUEST,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_FAIL,
  CLEAR_QUALIFICATIONS,
} from "../constants/qualificationConstants";

export const qualificationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUALIFICATIONS_REQUEST:
      return {
        loadingQualifications: true,
        errMsgQualifications: null,
        qualifications: [],
      };

    case GET_QUALIFICATIONS_SUCCESS:
      return {
        loadingQualifications: false,
        errMsgQualifications: null,
        qualifications: action.payload,
      };

    case GET_QUALIFICATIONS_FAIL:
      return {
        loadingQualifications: false,
        errMsgQualifications: action.payload,
        qualifications: [],
      };

    case CLEAR_QUALIFICATIONS:
      return {
        loadingQualifications: false,
        errMsgQualifications: null,
        qualifications: [],
      };

    default:
      return state;
  }
};
