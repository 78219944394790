import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  acceptSubscribeRequests,
  getSubscribeRequests,
  rejectSubscribeRequests,
} from "../redux/actions/courseActions";

const columns = [
  { id: "index", label: "ت" },
  { id: "courseName", label: "الكورس", minWidth: 170 },
  { id: "arabicName", label: "اسم الطالب", minWidth: 170 },
  { id: "phone1", label: "هاتف 1", minWidth: 170 },
  { id: "phone2", label: "هاتف 2", minWidth: 170 },
  { id: "address", label: "العنوان", minWidth: 170 },
  { id: "nationality", label: "الجنسية", minWidth: 170 },
  { id: "qualification", label: "المؤهل العلمي", minWidth: 170 },
  { id: "training_time", label: "وقت التدريب", minWidth: 170 },
  { id: "accept" },
  { id: "reject" },
];

function createData(
  id,
  index,
  courseName,
  arabicName,
  phone1,
  phone2,
  address,
  nationality,
  qualification,
  training_time
) {
  return {
    id,
    index,
    courseName,
    arabicName,
    phone1,
    phone2,
    address,
    nationality,
    qualification,
    training_time,
  };
}

const SubscribeRequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loadingRequests, requests } = useSelector(
    (state) => state.subscribeRequests
  );

  const [rows, setRows] = useState([]);

  const handleAccept = (requestId) => {
    dispatch(acceptSubscribeRequests(requestId));
  };
  const handleReject = (requestId) => {
    dispatch(rejectSubscribeRequests(requestId));
  };

  useEffect(() => {
    requests &&
      setRows(
        requests.map((request, index) => {
          return createData(
            request.id,
            ++index,
            request.course_name,
            request.name_arabic,
            request.phone_1,
            request.phone_2,
            request.address,
            request.nationality_name,
            request.qualification_name,
            request.training_time_name
          );
        })
      );
  }, [requests]);

  useEffect(() => {
    dispatch(getSubscribeRequests());
  }, []);

  return (
    <div className="subscribe-request-page">
      {loadingRequests === true ? (
        <CircularProgress />
      ) : (
        <Paper
          sx={{
            width: "100%",
            flexGrow: "1",
            overflow: "hidden",
          }}
        >
          <TableContainer style={{ height: "600px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className="table-head-row"
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id === "accept" ? (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              sx={{ color: "var(--color-2)" }}
                              onClick={() => handleAccept(row.id)}
                            >
                              <CheckCircleIcon />
                            </IconButton>
                          </TableCell>
                        ) : column.id === "reject" ? (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              sx={{ color: "var(--color-1)" }}
                              onClick={() => handleReject(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
};

export default SubscribeRequestPage;
