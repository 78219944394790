export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";

export const GET_COURSES_SUBSCRIBING_REQUEST =
  "GET_COURSES_SUBSCRIBING_REQUEST";
export const GET_COURSES_SUBSCRIBING_SUCCESS =
  "GET_COURSES_SUBSCRIBING_SUCCESS";
export const GET_COURSES_SUBSCRIBING_FAIL = "GET_COURSES_SUBSCRIBING_FAIL";

export const GET_COURSE_SUBSCRIBING_REQUEST = "GET_COURSE_SUBSCRIBING_REQUEST";
export const GET_COURSE_SUBSCRIBING_SUCCESS = "GET_COURSE_SUBSCRIBING_SUCCESS";
export const GET_COURSE_SUBSCRIBING_FAIL = "GET_COURSE_SUBSCRIBING_FAIL";
export const CLEAR_COURSE_SUBSCRIBING = "CLEAR_COURSE_SUBSCRIBING";

export const SELECT_COURSE_SUBSCRIBING = "SELECT_COURSE_SUBSCRIBING";

export const CLEAR_COURSES_SUBSCRIBING = "CLEAR_COURSES_SUBSCRIBING";
export const CLEAR_SELECTED_COURSE_SUBSCRIBING =
  "CLEAR_SELECTED_COURSE_SUBSCRIBING";

export const COURSE_SUBSCRIBE_REQUEST = "COURSE_SUBSCRIBE_REQUEST";
export const COURSE_SUBSCRIBE_SUCCESS = "COURSE_SUBSCRIBE_SUCCESS";
export const COURSE_SUBSCRIBE_DONE = "COURSE_SUBSCRIBE_DONE";
export const COURSE_SUBSCRIBE_FAIL = "COURSE_SUBSCRIBE_FAIL";

export const ADD_COURSES_INSTANCE_REQUEST = "ADD_COURSES_INSTANCE_REQUEST";
export const ADD_COURSES_INSTANCE_SUCCESS = "ADD_COURSES_INSTANCE_SUCCESS";
export const ADD_COURSES_INSTANCE_FAIL = "ADD_COURSES_INSTANCE_FAIL";

export const UPDATE_COURSES_INSTANCE_REQUEST =
  "UPDATE_COURSES_INSTANCE_REQUEST";
export const UPDATE_COURSES_INSTANCE_SUCCESS =
  "UPDATE_COURSES_INSTANCE_SUCCESS";
export const UPDATE_COURSES_INSTANCE_FAIL = "UPDATE_COURSES_INSTANCE_FAIL";

export const GET_SUBSCRIBE_REQUEST_REQUEST = "GET_SUBSCRIBE_REQUEST_REQUEST";
export const GET_SUBSCRIBE_REQUEST_SUCCESS = "GET_SUBSCRIBE_REQUEST_SUCCESS";
export const GET_SUBSCRIBE_REQUEST_FAIL = "GET_SUBSCRIBE_REQUEST_FAIL";

export const ACCEPT_SUBSCRIBE_REQUEST_REQUEST =
  "ACCEPT_SUBSCRIBE_REQUEST_REQUEST";
export const ACCEPT_SUBSCRIBE_REQUEST_SUCCESS =
  "ACCEPT_SUBSCRIBE_REQUEST_SUCCESS";
export const ACCEPT_SUBSCRIBE_REQUEST_FAIL = "ACCEPT_SUBSCRIBE_REQUEST_FAIL";

export const REJECT_SUBSCRIBE_REQUEST_REQUEST =
  "REJECT_SUBSCRIBE_REQUEST_REQUEST";
export const REJECT_SUBSCRIBE_REQUEST_SUCCESS =
  "REJECT_SUBSCRIBE_REQUEST_SUCCESS";
export const REJECT_SUBSCRIBE_REQUEST_FAIL = "REJECT_SUBSCRIBE_REQUEST_FAIL";
