import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { getCourseSubscribeBasicData } from "../redux/actions/mainActions";
import {
  getCoursesSubscribing,
  selectCourseSubscribing,
} from "../redux/actions/courseActions";

const MainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loadingCoursesSubscribing,
    errMsgCoursesSubscribing,
    coursesSubscribing,
  } = useSelector((state) => state.coursesSubscribing);

  const handleSubscribe = (id) => {
    dispatch(selectCourseSubscribing(id));
    navigate(`course-subscribe/${id}`);
  };
  
  useEffect(() => {
    dispatch(getCoursesSubscribing());
  }, []);

  return (
    <div className="main-page">
      {loadingCoursesSubscribing === true ? (
        <CircularProgress  />
      ) : (
        <div className="main-page-container">
          {coursesSubscribing.map((course) => {
            return (
              <Card className="course-card">
                <CardMedia
                  component="img"
                  alt="green iguana"
                  height="200"
                  image={course.image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {course.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {course.describtion}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="large"
                    onClick={() => handleSubscribe(course.id)}
                  >
                    إشترك الآن
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MainPage;
